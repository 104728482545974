import React, { useState, useEffect } from "react"
import {
  Button,
  Grid,
  Typography,
  Dialog,
  Paper,
  DialogActions,
  DialogContent,
} from "@material-ui/core"

import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Wave from "../components/SeperatorWave"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Lottie from "react-lottie"
import * as orderAnimationData from "../images/lottie/order2.json"
import * as cookAnimationData from "../images/lottie/cook3.json"
import * as deliveryAnimationData from "../images/lottie/delivery3.json"

import Plx from "react-plx"

import { window } from "browser-monads" //npm i browser-monads

const brands = {
  bite: {
    foodoraLink: "https://www.foodora.se/restaurant/new/slne/bite",
    //biteLink: "https://bitekitchens.com/order/home",
    name: "Bite",
  },
  smashingBurgers: {
    foodoraLink: "https://www.foodora.se/restaurant/new/d9jg/smashing-burgers",
    // biteLink: "https://bitekitchens.com/order/smashing-burgers",
    name: "Smashing Burgers",
  },
  pokiDoki: {
    foodoraLink: "http://foodora.se/restaurant/new/qm2l/pokidoki",
    //biteLink: "https://bitekitchens.com/order/poki-doki",
    name: "Poki Doki",
  },
  chickNBuns: {
    foodoraLink: "http://foodora.se/restaurant/new/oece/chick-n-buns",
    //biteLink: "https://bitekitchens.com/order/chick-n-buns",
    name: "Chick n' Buns",
  },
  happyRoots: {
    foodoraLink: "http://foodora.se/restaurant/new/hbez/happy-roots",
    // biteLink: "https://bitekitchens.com/order/happy-roots",
    name: "Happy Roots",
  },
  wonderWings: {
    foodoraLink: "https://www.foodora.se/restaurant/new/c13q/wonder-wings",
    //biteLink: "https://bitekitchens.com/order/wonder-wings",
    name: "Wonder Wings",
  },
  tastySquare: {
    foodoraLink: "http://foodora.se/restaurant/new/y0az/tasty-squared",
    //biteLink: "https://bitekitchens.com/order/tasty-square",
    name: "Tasty Square",
  },
  tacos: {
    foodoraLink: "http://foodora.se/restaurant/new/y0az/tasty-squared",
    //biteLink: "https://bitekitchens.com/order/tasty-square",
    name: "La Casa de Tacos",
  },
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    img1: file(relativePath: { eq: "food/1.jpeg" }) {
      ...image
    }
    img2: file(relativePath: { eq: "food/2.jpeg" }) {
      ...image
    }
    img3: file(relativePath: { eq: "food/3.jpeg" }) {
      ...image
    }
    img4: file(relativePath: { eq: "food/4.jpeg" }) {
      ...image
    }
    img5: file(relativePath: { eq: "food/5.jpeg" }) {
      ...image
    }
    img6: file(relativePath: { eq: "food/6.jpeg" }) {
      ...image
    }
    burgare: file(relativePath: { eq: "food/burgare.jpg" }) {
      ...image
    }
    sallad: file(relativePath: { eq: "food/sallad.jpg" }) {
      ...image
    }
    tacos: file(relativePath: { eq: "food/tacos.jpg" }) {
      ...image
    }
  }
`

const App = ({ data }) => {
  const { t } = useI18next()
  const images = data
  //const images = useStaticQuery(imageQuery);

  return (
    <>
      <Layout
        showBrands={false}
        colorClass="normalColor"
        footerBackgroundColor="#212322"
        reverseHeader
        hideFooterFeed
      >
        <SEO title={t("SEO_title_bite")} description={t("SEO_desc_bite")} />
        <div
          className="container"
          style={{
            marginTop: 40,
            marginBottom: "80px",
            borderRadius: "4px",
          }}
        >
          <div
            className={"info firstInfo desc reverse"}
            style={{
              background: "transparent",
              margin: 0,
            }}
          >
            <Typography
              variant="h1"
              align="center"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              {t("Lunds nya catering är här!")}
            </Typography>
          </div>
        </div>
        <div
          className="container reverse"
          style={{
            marginBottom: "80px",
            width: "calc(100% - 32px)",
            padding: 0,
          }}
        >
          <Grid container justify="space-around" alignItems="stretch">
            <Grid item sm={4} xs={12} style={{ marginBottom: 16 }}>
              <div className={"infoBox"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(255, 166, 137, 0.2)",
                      color: "rgba(255, 166, 137)",
                      width: 32,
                      height: 32,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 100,

                      marginRight: 12,
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    1
                  </div>
                  <Typography variant="h6">
                    {t("Från 55kr / person")}
                  </Typography>
                </div>
                <Typography paragraph>
                  {t(
                    "Alla event har olika budget, och det förstår vi. Vi har därförtagit fram en sallad perfekt för till exempel lunchföreläsningar med begränsad budget."
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item sm={4} xs={12} style={{ marginBottom: 16 }}>
              <div className={"infoBox"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(248, 94, 255, 0.2)",
                      color: "rgba(248, 94, 255)",
                      width: 32,
                      height: 32,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 100,

                      marginRight: 12,
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    2
                  </div>
                  <Typography variant="h6">
                    {t("Allt från sallad till tacos")}
                  </Typography>
                </div>
                <Typography paragraph>
                  {t(
                    "Ibland är inte alla sugna på samma mat, speciellt inte om det är för 150 personer. Från Bite kan ni i samma catering blandafritt mellan allt från sallad och poké bowls till pizza och tacos!"
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item sm={4} xs={12} style={{ marginBottom: 16 }}>
              <div className={"infoBox"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(153, 48, 255, 0.2)",
                      color: "#9930FF",
                      width: 32,
                      height: 32,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 100,

                      marginRight: 12,
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    3
                  </div>
                  <Typography variant="h6">
                    {t("Perfekt oavsett tillfälle")}
                  </Typography>
                </div>
                <Typography paragraph>
                  {t(
                    "Med allt från poké bowls till tacos och något i alla prisklasser har vår catering imponerat på såväl fullsatta lunchföreläsningar som påkostade företagsevent och privata fester"
                  )}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          className="container"
          style={{
            marginTop: 40,
            marginBottom: 120,
            borderRadius: "4px",
          }}
        >
          <div
            className={"info  desc reverse"}
            style={{
              background: "transparent",
              margin: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              align="center"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              {t("Vill du ha mer information eller lägga en beställning?")}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 18,
                fontSize: 12,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Button
                href={"mailto:philip@bitekitchens.com"}
                variant="outlined"
                color="primary"
                style={{
                  textTransform: "none",

                  fontSize: 16,
                  color: "white",

                  borderColor: "white",
                  marginLeft: 6,
                  marginRight: 6,
                }}
              >
                {t("Skicka förfrågan")}
              </Button>

              <Typography
                style={{ textAlign: "center", marginLeft: 6, marginRight: 6 }}
              >
                {t("eller maila till")}{" "}
                <a
                  //style={{ textDecoration: "underline" }}
                  href="mailto:philip@bitekitchens.com"
                >
                  philip@bitekitchens.com
                </a>
              </Typography>
            </div>
          </div>
        </div>
        {true && (
          <div className="container ">
            <Grid
              container
              justify="space-around"
              alignItems="center"
              spacing={2}
            >
              <Grid item sm={2} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <GatsbyImage
                    image={images.img1.childImageSharp.gatsbyImageData}
                    className={"img"}
                    alt="Hot chicken strips"
                  />
                </div>
              </Grid>
              <Grid item sm={2} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <GatsbyImage
                    image={images.img2.childImageSharp.gatsbyImageData}
                    className={"img"}
                    alt="Cheese fries with wings"
                  />
                </div>
              </Grid>
              <Grid item sm={2} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <GatsbyImage
                    image={images.img4.childImageSharp.gatsbyImageData}
                    className={"img"}
                    alt="Glazed Asian chicken"
                  />
                </div>
              </Grid>
              <Grid item sm={2} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <GatsbyImage
                    image={images.tacos.childImageSharp.gatsbyImageData}
                    className={"img"}
                    alt="Glazed Asian chicken"
                  />
                </div>
              </Grid>
              <Grid item sm={2} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <GatsbyImage
                    image={images.sallad.childImageSharp.gatsbyImageData}
                    className={"img"}
                    alt="Glazed Asian chicken with fries"
                  />
                </div>
              </Grid>
              <Grid item sm={2} xs={6} className={"logo"}>
                <div className={"logo"}>
                  <GatsbyImage
                    image={images.img6.childImageSharp.gatsbyImageData}
                    className={"img"}
                    alt="Glazed Asian chicken with fries"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Layout>
    </>
  )
}

export default App
